import Divider from '../../Divider.jsx'

const DestinationsGuide = ({ onHide, show, guide, organizationId }) => {
  const guides = {
    1: <ManagedGuide onHide={onHide} show={show} />,
    2: (
      <UnmanagedGuide
        onHide={onHide}
        show={show}
        organizationId={organizationId}
      />
    ),
    3: <ServiceAccountGuide onHide={onHide} show={show} />,
  }

  if (guide === null) {
    return <></>
  }
  return guides[guide]
}

const ManagedGuide = ({ onHide, show }) => {
  return (
    <div className={`guide ${show ? 'show' : ''}`}>
      <button onClick={() => onHide()} className="close-button">
        &times;
      </button>
      <h1>Managed by Maya</h1>
      <Divider spaceY={10} />
      <div className="step-heading"></div>
      <ol className="no-number">
        <li>
          With this option you are setting the database to be managed by Maya.
          This means that extracted data from all configured sources will be
          managed by Maya using our Bigquery. The data will be hosted inside the{' '}
          <b>EU</b> location.
          <br />
          <br />
          In order to access the database, configure the email access through
          the Shared Dataset option underline <u>Developer Settings</u>.
        </li>
        <li>
          ⚠️Please note that once a data source is set, the destination can’t be
          altered.
        </li>
      </ol>
      <div className="step-heading"></div>
    </div>
  )
}

const UnmanagedGuide = ({ onHide, show, organizationId }) => {
  return (
    <div className={`guide ${show ? 'show' : ''}`}>
      <button onClick={() => onHide()} className="close-button">
        &times;
      </button>
      <h1>Managed by your organization</h1>
      <Divider spaceY={10} />
      <div className="step-heading"></div>
      <ol className="no-number">
        <li>
          With this option, your organization will manage the database, and all
          data will be directly transferred to your BigQuery project. This setup
          ensures that the data from all configured sources is maintained within
          a system controlled by your organization.
        </li>
        <li>
          In order to set up the destination, you will need to set up the
          bigquery database. Before you begin, make sure your account has access
          to your organization's Google Cloud project with at least{' '}
          <b>Service Account Admin</b> and <b>BigQuery Admin</b> rights.
          <br />
          <br />
          <b>Note:</b> If you do not have a BigQuery project, you will need to
          create one before proceeding. Remember to also arrange a billing
          method, as the free trial does not cover data storage needs.
          <br />
          <br />
          <div className="img-item">
            <img
              src="https://old.mayainsights.com/static/img/icons/dest-guide-01.png"
              alt=""
              width="500"
            />
          </div>
          <ul>
            ✅ Step A: Go to{' '}
            <a
              target="_blank"
              style={{ textDecoration: 'underline' }}
              href="https://console.cloud.google.com/projectselector2/iam-admin/serviceaccounts?supportedpurview=project"
            >
              service account
            </a>
            . Click on <b>CREATE SERVICE ACCOUNT</b>
            <ol>
              <li>
                <b>Service account details</b>: Fill in the service account
                details by giving the account a service account name of your
                choice.
                <div className="img-item">
                  <img
                    src="https://old.mayainsights.com/static/img/icons/dest-guide-02.png"
                    alt=""
                    width="500"
                  />
                </div>
              </li>
              <li>
                <b>Grant this service account access to project</b>: Set the
                permissions <b>BigQuery Job User</b> and{' '}
                <b>BigQuery Read Session User</b>.
                <div className="img-item">
                  <img
                    src="https://old.mayainsights.com/static/img/icons/dest-guide-03.png"
                    alt=""
                    width="500"
                  />
                </div>
              </li>
              <li>
                <b>Grant users access to this service account</b>: no specific
                input is required. Just click done.
              </li>
              <li>
                Back in{' '}
                <a
                  target="_blank"
                  style={{ textDecoration: 'underline' }}
                  href="https://console.cloud.google.com/projectselector2/iam-admin/serviceaccounts?supportedpurview=project"
                >
                  service account
                </a>{' '}
                page, select your project, copy the service account email (i.e.
                column email) created to be used in the next step.
              </li>
            </ol>
            <br />✅ Step B: Go to Navigation Menu→BigQuery→
            <a
              href="https://console.cloud.google.com/bigquery"
              target="_blank"
              style={{ textDecoration: 'underline' }}
            >
              BigQuery Studio
            </a>
            .
            <br />
            You may be prompted to enable the Big Query API in order to proceed.
            Click on Enable.
            <ol>
              <li>
                In the <b>Explorer</b> panel, select the project where you want
                to create the dataset.
              </li>
              <li>
                Expand the <b>More Actions</b> option and click{' '}
                <b>Create dataset</b> (Screenshot below).
                <div className="img-item">
                  <img
                    src="https://old.mayainsights.com/static/img/icons/dest-guide-04.png"
                    alt=""
                    width="500"
                  />
                </div>
              </li>
              <li>
                On dataset_id input{' '}
                <span style={{ color: '#739570' }}>
                  prj_{organizationId}_db
                </span>{' '}
                and select the region/multi-region where you want to save your
                data. (Preferably somewhere close to your location or the
                location you already have your data stored in) Click{' '}
                <b>CREATE DATASET</b>.
              </li>
              <li>
                Click on the created dataset on the <b>Explorer</b> panel. Click
                on Sharing → Permissions → ADD PRINCIPAL → Then add the service
                account email copied from step B4 on New principals field, with
                role <b>BigQuery Admin</b>, and click Save.
                <div className="img-item">
                  <img
                    src="https://old.mayainsights.com/static/img/icons/dest-guide-05.png"
                    alt=""
                    width="500"
                  />
                </div>
                <br />
                <div className="img-item">
                  <img
                    src="https://old.mayainsights.com/static/img/icons/dest-guide-06.jpg"
                    alt=""
                    width="500"
                  />
                </div>
              </li>
            </ol>
          </ul>
        </li>
        <li>
          ⚠️ Please note that once a data source is set, the destination can’t
          be altered.
        </li>
      </ol>
      <div className="step-heading"></div>
    </div>
  )
}

const ServiceAccountGuide = ({ onHide, show }) => {
  return (
    <div className={`guide ${show ? 'show' : ''}`}>
      <button onClick={() => onHide()} className="close-button">
        &times;
      </button>
      <h1>Service accounts</h1>
      <Divider spaceY={10} />
      <div className="step-heading"></div>
      <ol className='no-number'>
        {/* the databse name should be dynamic in the future */}
        <li>Provide the service account credentials for Maya to transmit the data to your created <b>Google BigQuery</b> database:</li>
      <Divider spaceY={10} />
        <li>On the Service Account page → Keys → Add Key → Select in <b>JSON format</b> → store the credentials and send them through Maya’s interface.
</li>
<li>After providing the credentials you will be prompted to select a region/multi-region. Select the one that you chose during the database creation process.</li>
      </ol>
      <div className="step-heading"></div>
    </div>
  )
}

export default DestinationsGuide
