import ScreenLoading from 'components/ScreenLoading'
import React, { useEffect, useState } from 'react'
import './Destinations.scss'
import useDestinations from 'containers/destinations/useDestinations'
import {
  IDestination,
  BigQueryCredentials,
  IOrganization,
  DestinationStatus,
  DestinationLocation,
} from 'types'
import DestinationsGuide from './DestinationsGuide'
import UploadZone from 'components/UploadZone'
import { useHistory, useLocation } from 'react-router-dom'
import { beautifyNotificationDate } from 'utils/notificationUtils'
import DropDownField from 'components/DropDownField'

type Props = {
  currentOrganization: IOrganization
}

const Destinations: React.FC<Props> = ({ currentOrganization }) => {
  const {
    destinations,
    organizationDestination,
    hasConfiguredDestination,
    canConfigureDestination,
    getAllDestinations,
    getDestination,
    getOrganizationDestination,
    checkOrganizationDestinationStatus,
    removeOrganizationDestination,
    createOrUpdateOrganizationDestination,
  } = useDestinations(currentOrganization.id)
  // state
  const [showGuide, setShowGuide] = useState(null)
  const [previewOpen, setPreviewOpen] = useState(false)
  const [currentStep, setCurrentStep] = useState(1)
  const [showConfiguration, setShowConfiguration] = useState(false)
  const [destinationPreview, setDestinationPreview] =
    useState<IDestination | null>(null)

  // Main state
  const [selectedDestinationId, setSelectedDestinationId] = useState<
    number | null
  >(null)
  const [selectedAccount, setSelectedAccount] = useState(1)
  const [selectedLocation, setSelectedLocation] = useState('eu')
  const [credentials, setCredentials] = useState<BigQueryCredentials | null>(
    null
  )
  const [unmanagedDestinations, setUnmanagedDestinations] = useState<
    IDestination[]
  >([])
  const [managedDestinations, setManagedDestinations] = useState<
    IDestination[]
  >([])

  const history = useHistory()

  const handleClickContinue = () => {
    if (
      currentStep === 1 &&
      selectedDestinationId !== null &&
      managedDestinations.map((dest) => dest.id).includes(selectedDestinationId)
    ) {
      // if the user chose a managed destination then we need to remove any existing unmanaged destinations if any
      removeOrganizationDestination().then(() => {
        history.push(`/organization/${currentOrganization.slug}/sources`)
      })
      return
    } else if (currentStep === 3) {
      createOrUpdateOrganizationDestination(
        selectedDestinationId,
        destinations!.find((dest) => dest.id === selectedDestinationId)!.name,
        credentials,
        selectedLocation
      )
        .then(() => {
          history.push(`/organization/${currentOrganization.slug}/sources`)
        })
        .catch((err) => alert(err))
      return
    }

    setCurrentStep((prev) => prev + 1)
  }

  const handleClickBack = () => {
    if (currentStep !== 1) setCurrentStep((prev) => prev - 1)
    else setShowConfiguration(false)
  }

  const handleIsDisabled = () => {
    if (selectedDestinationId === null) {
      return true
    }

    if (currentStep > 1 && credentials === null) {
      return true
    }

    if (currentStep > 2 && selectedLocation === null) {
      return true
    }

    return false
  }

  // fetch resources
  useEffect(() => {
    if (
      destinations?.length <= 0 ||
      destinations === null ||
      destinations === undefined
    ) {
      getAllDestinations()
      getOrganizationDestination()
      checkOrganizationDestinationStatus()
    }
  }, [currentOrganization.id, destinations])

  useEffect(() => {
    const getDestinationData = async () => {
      if (organizationDestination !== null) {
        const data = await getDestination(
          organizationDestination.destination_id
        )
        setDestinationPreview(data)
      }
    }

    getDestinationData()
  }, [organizationDestination])

  // sort destinations
  useEffect(() => {
    if (
      (destinations?.length > 0 && managedDestinations?.length === 0) ||
      unmanagedDestinations?.length === 0
    ) {
      const managed: IDestination[] = []
      const unamanaged: IDestination[] = []
      destinations.map((destination) => {
        if (destination.managed) {
          managed.push(destination)
          if (selectedDestinationId === null) {
            setSelectedDestinationId(destination.id)
          }
        } else {
          unamanaged.push(destination)
        }
      })
      setManagedDestinations(managed)
      setUnmanagedDestinations(unamanaged)
    }
  }, [destinations])

  useEffect(() => {
    setShowConfiguration(!hasConfiguredDestination)
  }, [hasConfiguredDestination])

  // if (!canConfigureDestination && !hasConfiguredDestination) {
  //   return <p>You cannot configure a destination</p>
  // }

  if (!currentOrganization || !currentOrganization?.id) {
    window.location.reload()
  }

  return (
    <div className="destination-wrapper">
      <DestinationsGuide
        show={showGuide !== null}
        onHide={() => setShowGuide(null)}
        guide={showGuide}
        organizationId={currentOrganization.id}
      />
      <h2>Configure a Destination for your organization</h2>
      {destinations?.length > 0 ? (
        showConfiguration ? (
          <>
            <span
              onClick={handleClickBack}
              className="material-icons back-arrow non-selectable"
            >
              arrow_back
            </span>
            <Steps
              currentStep={currentStep}
              managedDestinations={managedDestinations}
              selectedDestination={selectedDestinationId}
              setSelectedDestination={setSelectedDestinationId}
              setShowGuide={setShowGuide}
              showGuide={showGuide}
              unmanagedDestinations={unmanagedDestinations}
              selectedAccount={selectedAccount}
              setSelectedAccount={setSelectedAccount}
              selectedLocation={selectedLocation}
              setSelectedLocation={setSelectedLocation}
              setCredentials={setCredentials}
            />
            <button
              // className={`submit-button ${
              //   handleIsDisabled() ? 'disabled' : ''
              // }`}
              // disabled={handleIsDisabled()}
              className="submit-button"
              onClick={handleClickContinue}
            >
              {currentStep < 3 ? 'Continue' : 'Finish'}
            </button>
          </>
        ) : (
          <DestinationPreview
            canConfigureDestination={canConfigureDestination}
            destinationPreview={destinationPreview}
            organizationDestination={organizationDestination}
            previewOpen={previewOpen}
            setPreviewOpen={setPreviewOpen}
            setShowConfiguration={setShowConfiguration}
          />
        )
      ) : (
        <>No destinations</>
      )}
    </div>
  )
}

const DestinationList = ({
  destinationCategory,
  destinations,
  selectedDestination,
  setSelectedDestination,
  guide,
  showGuide,
  setShowGuide,
}) => {
  const handleOpenGuide = () => {
    if (showGuide === null) {
      setShowGuide(guide)
    } else if (showGuide !== guide) {
      setShowGuide(guide)
    }
  }

  return (
    <div className="destination-category">
      <div className="destination-category-info">
        <h2>{destinationCategory}</h2>
      </div>
      <div className="destinations">
        {destinations?.length > 0 ? (
          destinations.map((destination) => (
            <DestinationButton
              key={destination.id}
              onClick={handleOpenGuide}
              destination={destination}
              selectedDestination={selectedDestination}
              setSelectedDestination={setSelectedDestination}
            />
          ))
        ) : (
          <ScreenLoading />
        )}
      </div>
    </div>
  )
}

const DestinationButton = ({
  onClick,
  destination,
  selectedDestination,
  setSelectedDestination,
}) => {
  const handleClick = () => {
    onClick()
    setSelectedDestination(destination.id)
  }

  return (
    <div
      className={`destination-button ${
        selectedDestination === destination.id ? 'selected' : ''
      }`}
      onClick={handleClick}
    >
      <div className="info">
        <img alt={destination.id} width="90" src={destination.logo}></img>
        <span className="name">{destination.name}</span>
        {destination.managed && <span className="name default">(Default)</span>}
      </div>
    </div>
  )
}

export default Destinations

const Steps = ({
  currentStep,
  managedDestinations,
  unmanagedDestinations,
  selectedDestination,
  setSelectedDestination,
  showGuide,
  setShowGuide,
  selectedAccount,
  setSelectedAccount,
  selectedLocation,
  setSelectedLocation,
  setCredentials,
}) => {
  const [clearFile, setClearFile] = useState(false)
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [isValidCredentialSchema, setIsValidCredentialSchema] = useState()
  const [destinationLocations, setDestinationLocations] = useState<
    { value: string; label: string }[]
  >([])

  const enumToList = (enumObj) => {
    const list: { label: string; value: string }[] = []
    for (const key in enumObj) {
      if (isNaN(Number(enumObj[key]))) {
        const words = key.split('_')
        // .map(
        //   (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        // )
        const label = words.join(' ')
        list.push({ label, value: enumObj[key] })
      }
    }
    return list
  }

  useEffect(() => {
    setDestinationLocations(enumToList(DestinationLocation))
  }, [])

  useEffect(() => {
    if (currentStep === 1) {
      setShowGuide(1)
    } else if (currentStep === 2) {
      setShowGuide(3)
    }
  }, [currentStep])

  const Step1 = () => {
    return (
      <>
        <DestinationList
          destinations={managedDestinations}
          destinationCategory={'Managed by Maya'}
          selectedDestination={selectedDestination}
          setSelectedDestination={setSelectedDestination}
          guide={1}
          showGuide={showGuide}
          setShowGuide={setShowGuide}
        />
        <DestinationList
          destinations={unmanagedDestinations}
          destinationCategory={'On your Big Query'}
          selectedDestination={selectedDestination}
          setSelectedDestination={setSelectedDestination}
          guide={2}
          showGuide={showGuide}
          setShowGuide={setShowGuide}
        />
      </>
    )
  }
  const Step2 = () => {
    const processUploadedFile = (e) => {
      if (e?.target?.files?.length > 0) {
        setSelectedFile(e.target.files[0])
      } else {
        return
      }

      const reader = new FileReader()
      reader.readAsText(e.target.files[0])
      reader.onload = () => {
        const contents = reader.result
        try {
          const jsonData: BigQueryCredentials = JSON.parse(contents as string)

          // TODO: we have the file parsed as a json object now and we can send it to the backend sync w/ GIannis to figure out what to do
          // we can do a check as well to see if the json object contains the necessary fields we want before we continue
          setCredentials(jsonData)
        } catch (error) {
          console.error('Error parsing JSON:', error)
        }
      }
    }

    const handleOpenGuide = () => {
      if (showGuide === 3) {
        setShowGuide(null)
      } else {
        setShowGuide(3)
      }
    }

    const handleClearFile = (e) => {
      e.stopPropagation()
      setClearFile(true)
      setSelectedFile(null)
      setCredentials(null)
    }

    return (
      <div className="account-selection-wrapper">
        <div className="option-header">
          <h2>Choose one of the two options</h2>
          <span onClick={handleOpenGuide} className="material-icons">
            help
          </span>
        </div>
        <div className="account-selection-body">
          <div
            className={`option ${selectedAccount === 1 ? 'selected' : ''}`}
            onClick={() => setSelectedAccount(1)}
          >
            <div className="option-header">
              <span className="material-icons-outlined">person_search</span>
              <p>Your service principal</p>
            </div>
            {selectedAccount === 1 && (
              <div className="option-body">
                <p>
                  Upload a '.json' file containing the credentials of the
                  service principal
                </p>
                {!selectedFile ? (
                  <UploadZone
                    clear={clearFile}
                    fileType="json"
                    uploadFile={processUploadedFile}
                    uploadingFile={false}
                  ></UploadZone>
                ) : (
                  <p onClick={handleClearFile} className="selected-file">
                    &times; {selectedFile.name}
                  </p>
                )}
              </div>
            )}
          </div>
          <div
            className={`option disabled ${
              selectedAccount === 2 ? 'selected' : ''
            }`}
            onClick={() => {
              // TODO: it's disabled for now sync w/ Giannis
              // setSelectedAccount(2)
            }}
          >
            <div className="option-header">
              <span className="material-icons-outlined">people</span>
              <p>Our service principal</p>
            </div>
            {selectedAccount === 2 && <div className="option-body"></div>}
          </div>
        </div>
      </div>
    )
  }

  const Step3 = () => {
    return (
      <div className="dropdown-wrapper">
        <h2>Choose a location closer to your data</h2>
        <DropDownField
          label="Choose a location"
          onChange={(v) => setSelectedLocation(v)}
          options={destinationLocations}
          value={selectedLocation}
          filterWithSimilarity
          style={{
            width: '30vw',
          }}
        />
      </div>
    )
  }
  const steps = {
    1: <Step1 />,
    2: <Step2 />,
    3: <Step3 />,
  }

  return steps[currentStep]
}

const DestinationPreview = ({
  destinationPreview,
  organizationDestination,
  previewOpen,
  setPreviewOpen,
  canConfigureDestination,
  setShowConfiguration,
}) => {
  return (
    <>
      <div className="destination-preview-wrapper">
        {destinationPreview !== null ? (
          <div className="destination-preview-container">
            <div
              className="preview-header"
              onClick={() => setPreviewOpen((prev) => !prev)}
            >
              <div className="preview-info">
                <img src={destinationPreview.logo} alt="" />
                <p>{destinationPreview.name}</p>
              </div>
              <span className="material-icons">
                keyboard_arrow_{!previewOpen ? 'down' : 'up'}
              </span>
            </div>
            {previewOpen && (
              <div className="preview-body">
                <div className="preview-body__item">
                  <span className="material-icons">manage_accounts</span>
                  <p>
                    Destination managed by Maya:{' '}
                    {destinationPreview.managed ? (
                      <span className="true">True</span>
                    ) : (
                      <span className="false">False</span>
                    )}
                  </p>
                </div>
                <div className="preview-body__item">
                  <span className="material-icons">map</span>
                  <p>
                    Location:{' '}
                    <span className="true">
                      {organizationDestination.location}
                    </span>
                  </p>
                </div>
                <div className="preview-body__item">
                  <span className="material-icons">settings_applications</span>
                  <p>
                    Can modify destination:{' '}
                    {canConfigureDestination ? (
                      <span className="true">True</span>
                    ) : (
                      <span className="false">False</span>
                    )}
                  </p>
                </div>
                <div className="preview-body__item">
                  <span className="material-icons">event_available</span>
                  <p>
                    Date created:{' '}
                    <span className="date">
                      {beautifyNotificationDate(
                        organizationDestination.created_at
                      )}
                    </span>
                  </p>
                </div>
                <div className="preview-body__item">
                  <span className="material-icons-outlined">today</span>
                  <p>
                    Date updated:{' '}
                    <span className="date">
                      {beautifyNotificationDate(
                        organizationDestination.updated_at
                      )}
                    </span>
                  </p>
                </div>
              </div>
            )}
          </div>
        ) : (
          'User has configured organization'
        )}
        {/* {canConfigureDestination ( */}
        <button
          className="submit-button"
          onClick={() => setShowConfiguration(true)}
        >
          Modify
        </button>
        {/* )} */}
      </div>
    </>
  )
}
