import React, { useEffect, useContext, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import ScreenLoading from 'components/ScreenLoading'
import FileUpload from 'components/FileUpload/FileUpload'

import useGetAuthUrl from 'containers/services/useGetAuthUrl'
import useGetServices from 'containers/services/useGetServices'
import OrganizationsContext from 'containers/organizations/organizationsContext'

import './AddSource.scss'

import * as rudderanalytics from 'rudder-sdk-js'
import Feature from 'components/Feature'
import TagManager from 'react-gtm-module'

const AddServiceButton = ({ service, handleConnectService, loadingAuthUrlServiceId, connectDisabled, disabled = false}) => {
  const history = useHistory();
  const [showPopup, setShowPopup] = useState(false)
  const [manualUploadCategory, setManualUploadCategory] = useState(null)
  const {currentOrganization} = useContext(OrganizationsContext)

  const handleClick = () => {
    if (disabled) {
      history.push(`/organization/${currentOrganization.slug}/billing/details?checkout=1`)
      return
    }
    const traits = {
      service_id: service.id,
      service__is_listed: service.is_listed,
      service_name: service.name,
      service_category: service.category,
    }
    TagManager.dataLayer({ dataLayer: { 
      event: 'new_source_button_clicked',
      is_custom_track: true,
      event_params: {
        serviceId: service.id,
        serviceIsListed: service.is_listed,
        serviceMame: service.name,
        serviceCategory: service.category,
      }
    }})
    // rudderanalytics.track('new_source_button_clicked', traits)

    localStorage.removeItem('serviceAccounts')

    switch (service.id) {
      case 'google_play':
        history.push(`setup/google_play/${service.uuid}`)
        break
      case 'apple_ads':
        history.push(`setup/apple_ads/${service.uuid}`)
        break
      case 'google_analytics_4_events':
        history.push(`setup/google_analytics_4_events/${service.uuid}`) 
        break
      case 'manual_orders':
      case 'manual_users':
      case 'manual_expenses':
        history.push(`setup/custom-table/${service.id}`)
        break
      case 'manual_search_term':
        if (showPopup === false) {
          setShowPopup(true)
          setManualUploadCategory('manual_search_term')
        }
        break
      case 'manual_ads_performance':
        if (showPopup === false) {
          setShowPopup(true)
          setManualUploadCategory('manual_ads_performance')
        }
        break
      case 'manual_ads_conversions':
        if (showPopup === false) {
          setShowPopup(true)
          setManualUploadCategory('manual_ads_conversions')
        }
        break
      default:
        handleConnectService(service.uuid)
    }
  }

  return (
    <div className={`source-button ${connectDisabled || disabled ? 'disabled' : ''}`} onClick={handleClick}>
      <div className='info'>
        {loadingAuthUrlServiceId === service.id ?
          <ScreenLoading /> : (
          <>
            <img alt={service.id} width="90" src={service.logo}></img>
            <span className="name">{service.name}</span>
          </>
        )}
      </div>
      <button className={`link ${disabled ? "upgrade-btn" : ""}`}>{disabled ? <span>Upgrade plan</span> : <><span className='enlarge'>+</span>Connect</>}</button>
      {manualUploadCategory !== null && <FileUpload show={showPopup} onHide={() => setShowPopup(false)} category={manualUploadCategory}/>}
    </div>
  )
}

const ServiceCategory = ({
  categoryName,
  category,
  handleConnectService,
  loadingAuthUrlServiceId,
  connectDisabled,
}) => {
  return (
    <div className='integrations-category'>
      <h3 className='title'>{categoryName}</h3>
      <div className='services'>
        {category.map(service =>
            <Feature feature={(features) => features.is_trial || features.integrations !== undefined && features.integrations.find(integration => integration === service.id)} switchComponent={<AddServiceButton key={service.id} service={service} handleConnectService={handleConnectService} loadingAuthUrlServiceId={loadingAuthUrlServiceId} connectDisabled={connectDisabled} disabled/>}>
              <AddServiceButton key={service.id} service={service}
                                handleConnectService={handleConnectService}
                                loadingAuthUrlServiceId={loadingAuthUrlServiceId}
                                connectDisabled={connectDisabled} />
            </Feature>
          )
        }
      </div>
    </div>
  )
}

const AddSource = () => {
  let history = useHistory()
  let location = useLocation()

  const { services, servicesError, getServices, loadingServices } =
    useGetServices()

  const { loadingAuthUrlServiceId, authUrlError, getAuthUrl } = useGetAuthUrl()

  const { currentOrganization, isLoadingOrganization } =
    useContext(OrganizationsContext)

  const getData = async () => {
    getServices()
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, [currentOrganization])

  if (servicesError) {
    return <>{servicesError}</>
  }

  if (loadingServices) {
    return <ScreenLoading />
  }

  let organizedServices = {}

  services
    .filter((s) => s.is_listed)
    .forEach((service) => {
      if (!organizedServices[service.category]) {
        organizedServices[service.category] = []
      }

      organizedServices[service.category].push(service)
    })

  const handleBack = () => {
    history.push(`/organization/${currentOrganization.slug}/sources`)
  }

  const handleConnectService = async (serviceUuid) => {
    const url = await getAuthUrl(serviceUuid, currentOrganization.id)
    if (url) {
      window.location.replace(url)
    }
  }

  return (
    <div className="add-source">
      {location.state && location.state.error && (
        <div className="alert alert-danger" role="alert">
          Something went wrong, please try again later or try again with a
          different account. ({location.state.error})
        </div>
      )}
      {authUrlError && (
        <div className="alert alert-danger" role="alert">
          {authUrlError}
        </div>
      )}
      <span
        onClick={handleBack}
        className="material-icons back-arrow non-selectable"
      >
        arrow_back
      </span>
      <div className="head">
        <h2>Integrations</h2>
        <span className="subtitle-text">
          Select the 3rd party services you use at your company. Try to add as
          many as possible so we will extract and normalize your data in an
          optimal way
        </span>
      </div>
      <div className="content">
        {Object.keys(organizedServices).map((categoryName) => (
          <ServiceCategory
            key={categoryName}
            categoryName={categoryName}
            category={organizedServices[categoryName]}
            handleConnectService={handleConnectService}
            loadingAuthUrlServiceId={loadingAuthUrlServiceId}
            connectDisabled={isLoadingOrganization}
          />
        ))}
      </div>
    </div>
  )
}

export default React.memo(AddSource)
