import React, { useState, FocusEvent, useMemo, useEffect } from 'react'
import _ from 'lodash'

export interface IInputField {
  type: string
  label?: string
  initialValue: string
  name?: string
  setValue: (v: string) => void
  value: string
  setCurrentValue?: React.Dispatch<React.SetStateAction<string>>
  error?: string
  removeError?: Function | null
  required?: boolean
  style?: any
  disabled?: boolean
  placeholder?: string
  placeholder?: string
}

const InputField = ({
  label = '',
  type,
  initialValue = '',
  name = '',
  setValue,
  value,
  setCurrentValue,
  error = '',
  removeError = null,
  required = false,
  style = {},
  disabled = false,
  placeholder = '',
}: IInputField) => {
  const [isFocused, setIsFocused] = useState(false)
  const [showError, setShowError] = useState(true)

  useEffect(() => {
    setShowError(true)
  }, [error])

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    setIsFocused(false)
    if (removeError) {
      removeError()
    }
    if (setValue) {
      setValue(event.target.value)
    }
  }

  const id = 'inputfield-' + useMemo(() => _.uniqueId(), [])

  return (
    <div
      className={`${disabled ? 'disabled' : ''} input-field ${
        isFocused ? 'focused' : ''
      } ${showError && error ? 'error' : ''}`}
      style={style}
    >
      <div className={`${disabled ? 'disabled' : ''} input-header`}>
        <label htmlFor={id}>{label}</label>
        {showError && error ? (
          <span className="error-message">{error}</span>
        ) : (
          <></>
        )}
      </div>
      <div className="input-container">
        <input
          id={id}
          disabled={disabled}
          onFocus={() => setIsFocused(true)}
          onBlur={handleBlur}
          onChange={(e) => {
            if (setCurrentValue) setCurrentValue(e.target.value)
            if (setValue) setValue(e.target?.value)
          }}
          value={initialValue || value || ''}
          name={name}
          placeholder={placeholder}
          type={type}
          required={required}
        />
      </div>
    </div>
  )
}

export default React.memo(InputField)
