import React, { useEffect, useState } from 'react'
import '../pages/Dashboard/Dashboard.scss'

const DEMOS = {
    'Acquisition': "https://app.powerbi.com/view?r=eyJrIjoiNzgwNDEzMDMtYTI4Zi00NTkyLWE3NmMtM2Q0MjE5ZWJmMjdmIiwidCI6IjljNTBhNTYwLTE0OGQtNDE3NC05MDkwLTljYjhkZGZjNzIyYiIsImMiOjl9",
    'Audience': "https://app.powerbi.com/view?r=eyJrIjoiNjc3MmM5ZTYtMTMyMS00MGFiLWE1MDAtYmZlYWNmMmNjZTgyIiwidCI6IjljNTBhNTYwLTE0OGQtNDE3NC05MDkwLTljYjhkZGZjNzIyYiIsImMiOjl9",
    'Behavior': "https://app.powerbi.com/view?r=eyJrIjoiMGNmOTYyYmYtMjFhYS00YWMwLTg1NDgtYWE2MTE0N2RhZDNmIiwidCI6IjljNTBhNTYwLTE0OGQtNDE3NC05MDkwLTljYjhkZGZjNzIyYiIsImMiOjl9",
    "Conversions": "https://app.powerbi.com/view?r=eyJrIjoiMzEzMmZjMzgtYjJjMC00OTc2LTkwM2YtNDQ0YjU2NTIxNjliIiwidCI6IjljNTBhNTYwLTE0OGQtNDE3NC05MDkwLTljYjhkZGZjNzIyYiIsImMiOjl9",
    'Competition': "https://app.powerbi.com/view?r=eyJrIjoiODk5MTFhZTAtZjkzOS00YTY3LThmYzctMzg2ZWM2YzU0ODljIiwidCI6IjljNTBhNTYwLTE0OGQtNDE3NC05MDkwLTljYjhkZGZjNzIyYiIsImMiOjl9&pageName=ReportSection9b0b0645375230719d4e"
}

const Demo = ({reportSlug}) => {
    // check orgpicker to default to seo
  return (
    <div className="demo">
      <iframe src={DEMOS[reportSlug  || 'Acquisition']} frameborder="0" scrolling="no" allowfullscreen="true" width="100%" height="100%"></iframe>
    </div>
  )
}

export default React.memo(Demo)
