export interface IToken {
  accessToken: string
}

export interface IOrganization {
  id: number
  name: string
  slug: string
  currency_id: string
  is_etl_enabled: boolean
  is_demo?: boolean
  has_subscription: boolean
}

export interface IProfile {
  first_name: string
  last_name: string
}

export interface IUser {
  id: number
  email: string
  profile: IProfile
  organizations_count: number
}

export interface IService {
  id: string
  name: string
  logo: string
  is_listed: boolean
}

export type ISyncStatus = 'pending' | 'running' | 'finished' | 'failed'

export interface IServiceAccount {
  id: number
  uuid: string
  name: string
  id_label: string
  sync_status: ISyncStatus
  sync_error: string | null
  last_synced_at: string | null
  connected_at: string
  service: string
}

export interface IPasswordLogin {
  email: string
  password: string
}

export interface IGoogleLogin {
  google_id_token: string
}

export interface IMicrosoftLogin {
  microsoft_code?: string
  microsoft_access_token?: string
  is_mona?: boolean
}

export type ILoginCredentials = IPasswordLogin | IGoogleLogin | IMicrosoftLogin

export interface IWordGroup {
  id: string
  name: string
  deletion_protection: boolean
  terms: string[]
  excludeTerms: string[]
}

export interface IClusteringRule {
  condition?: string
  operator: string
  field: string
  value: string
}

export interface IClusteringValue {
  id: string
  name: string
  rules: IClusteringRule[]
}

export interface IClusteringColumn {
  id: string
  name: string
  values: IClusteringValue[]
}

export interface ICurrency {
  name: string
  code: string
  symbol: string
}

export interface IManualExpense {
  category: string
  subcategory: string
  amount: number
  currencyCode: string
  paymentDate: string
  startDate?: string | null
  endDate?: string | null
  uuid: string
  description: string
  isMarketingRelated: boolean
  serviceId?: string | null
}

export interface IInvite {
  id: string
  uuid: string
  expires_at: string
  email: string
}

export interface IPageSpeedLink {
  uuid: string
  urlLink: string
}

export interface ISubscription {
  status: string
  needs_payment_method: boolean
  trial_ends_at: string | null
  cancel_at: string | null
}

export type Country = {
  code: string
  name: string
}

export interface IBillingDetails {
  companyName: string
  countryCode: string
  addressLine1: string
  addressLine2: string
  city: string
  state: string
  postalCode: string
  taxId: string
  taxOffice: string
}

export type CheckoutPlan = {
  amount: number
  plan_name: string
  currency: string
}

export type IEmbedReport = {
  id: string
  name: string
  embed_url: string
  slug: string
  access_level: string
  permission_level: string
  workspace_id: string
  demo: boolean
  is_custom: boolean
  dataset_id: string
}

export type EmailDomainExists = {
  exists: boolean
}

export type ETLReponse = {
  latest_etl_date: Date
  all_logs: IETLlogs
}

export interface IETLlogs {
  [key: string]: ETLlog[]
}

export type ETLlog = {
  step: string
  etl_started_at: Date
  sync_started_at: Date
  run_time: number
}
export type Conversation = {
  visitorId: string
  message: string
}

export type DatasetResponse = {
  success: boolean
  message: string
}

export type DatasetUser = {
  email: string
  archived: boolean
  authorized: boolean
}

export type FeatureResponse = {
  features: Features | null
}

export type Features = {
  can_add_accounts: boolean
  can_add_users: boolean
  has_database_access: boolean
  integrations: string[]
  is_trial: boolean
}

export type SearchTermsResponse = {
  ok: boolean
}

export type APIKey = {
  key: string
}

export interface IColumns {
  [key: string]: Column[]
}

export type Column = {
  columnName: string
  alias: string
  type: string
  isRequired: boolean
  isPrimaryKey?: boolean
}

export interface ICustomTable {
  report_name: string
  report_category: string
  gcs_uri: string
  schema: {
      columns: {
          name: string
          alias: string
          primary_key: boolean
          type: string
          default: boolean
      }[]
  }
}

export type MicrosoftRedirectParams = {
  isMona: boolean,
  monaSubscription: string
}

export type CreateMonaParams = {
  microsoftCode: string,
  subscription: string
}

export type Subscription = {
    id: string,
    organization: IOrganization | null
    customer_user: IUser | null
    name: string,
    plan_id: string,
    customer_id: string,
    annual: boolean
    is_private: boolean
    is_recurring: boolean
    status: string,
    cancel_at: Date | null
}

export interface ISubscriptionDetailsResponse {
    data: {
      subscription: Subscription
    }
    success: boolean
}

export type SubscriptionResponse = {
  features: Features | null
}

export enum NotificationType {
  Info,
  Error,
  Warning,
  Success,
  Persistent,
}

export enum NotificationStatus {
  All,
  Unread,
  Read,
  Archived,
}

export enum ActionType {
  MODAL,
  REDIRECT
}

export enum ActionStatus {
  FULFILLED,
  PENDING,
  REJECTED
}

export interface IAction {
  id: number,
  notification_id: string,
  name: string,
  type: ActionType,
  status: ActionStatus,
  created_at: string,
}

export interface INotification {
  id: string,
  organization_id: number,
  title: string,
  body?: string
  status: NotificationStatus,
  type: NotificationType,
  pinned: boolean,
  action?: IAction,
  created_at: string
  updated_at: string
} 

export interface INotificationFilter {
  notificationId: string,
  type?: NotificationType
  status?: NotificationStatus
  pinned?: boolean
}

export type PaginatedNotificationsParams = {
  cursor: number | null,
  limit: number,
}

export type NotificationCountParams = {
  type?: NotificationType[],
  status?: NotificationStatus[],
  tags?: string[]
}

export interface IRefreshParams {
  date: string
}

export interface INotificationContext {
  notifications: INotification[] | null
  notificationCursor: number | null
  latestNotification: INotification | null
  updateNotification?: (params: INotificationFilter) => Promise<INotificationUpdateStatus | null>
  refreshNotifications?: (params: IRefreshParams) => Promise<INotification[] | null>
  nextNotifications?: () => Promise<void>
  getNotificationCount?: (params?: NotificationCountParams) => Promise<number | null>
}

export interface INotificationUpdateStatus {
  success?: boolean
  detail?: string,
  updated_notification?: INotification 
}

export interface INotificationFilterResponse {
  notifications: INotification[]
  next_page: number | null
}

export interface IDestination {
  id: number
  db_id: number
  name: string
  logo: string
  managed: boolean
}

export interface IOrganizationDestination {
  id: number
  organization: number
  name: string
  destination_id: number
  status: number
  created_at: Date
  updated_at: Date
}

export interface BigQueryCredentials {
  type: string,
  projectId: string,
  privateKeyId: string,
  privateKey: string,
  clientEmail: string,
  clientId: string,
  authUri: string,
  tokenUri: string,
  authProviderX509CertUrl: string,
  clientX509CertUrl: string,
  universeDomain: string,
}

export enum DestinationStatus {
  PENDING = 1,
  FINALIZED = 2,
  PATCHED = 3
}

export enum DestinationLocation {
  US = "us",
  EU = "eu",
  DOHA = "me-central1",
  TEL_AVIV = "me-west1",
  FINLAND = "europe-north1",
  FRANKFURT = "europe-west3",
  HONG_KONG = "asia-east2",
  SYDNEY = "australia-southeast1",
  DALLAS = "us-south1",
  MONTREAL = "northamerica-northeast1",
  OREGON = "us-west1",
  SAO_PAULO = "southamerica-east1",
  SOUTH_CAROLINA = "us-east1",
  JOHANNESBURG = "africa-south1"
}

export enum ProductMode {
  SUBSCRIPTION = "subscription",
  PAYMENT = "payment",
}

export type Product = {
  id: number,
  title: string, 
  desc: string, 
  is_most_popular: boolean, 
  is_active: boolean, 
  has_custom_price: boolean, 
  order: number, 
  mode: ProductMode, 
  annual_price: number | null,
  monthly_price: number | null,
  fixed_price: number | null,

  stripe_product_id: string, 

  accounts: string, 
  users: string, 
  default_reports: string, 
  email_support: string, 
  priority_support: string, 
  data_history: string, 
  custom_reports: string, 
  powerbi_access: string, 
  database: string, 
  onboarding: string, 

  integrations: string, 
  integration_id: string, 
  can_purchase: boolean
}