import api, { isHTTPError } from 'containers/api'
import { useState } from 'react'
import { selectToken } from 'containers/authentication/authenticationSlice'
import { useSelector } from 'react-redux'
import { IDestination, BigQueryCredentials, IOrganizationDestination } from 'types'

const useDestinations = (organizationId) => {
  const token = useSelector(selectToken)

  const [authURIError, setAuthURIError] = useState<string | null>(null)
  const [destinations, setDestinations] = useState<IDestination[]>([])
  const [organizationDestination, setOrganizationDestination] = useState<IOrganizationDestination | null>(null)
  const [hasConfiguredDestination, setHasConfiguredDestination] = useState(false)
  const [canConfigureDestination, setCanConfigureDestination] = useState(true)
  
  
  const getAllDestinations = async (): Promise<IDestination[] | null> => {
    try {
      const tempDestinations = await api.getAllDestinations({
        organizationId,
        token,
      })
      setDestinations(tempDestinations)
      return tempDestinations
    } catch (error) {
      if (isHTTPError(error) && error.response.status === 404) {
        setAuthURIError('Invalid organization')
      } else if (isHTTPError(error) && 'detail' in error.response.data) {
        setAuthURIError(
          'Error getting destinations: ' + error.response.data.detail.type
        )
      }
      return null
    }
  }

  const getOrganizationDestination = async () => {
    try {
      const destination = await api.getOrganizationDestination({
        organizationId,
        token,
      })
      setHasConfiguredDestination(true)
      setOrganizationDestination(destination)
      return destination
    } catch (error) {
      if (isHTTPError(error) && error.response.status === 404) {
        setAuthURIError('Invalid organization')
      } else if (isHTTPError(error) && 'detail' in error.response.data) {
        setAuthURIError(
          'Error getting organization destination: ' +
            error.response.data.detail.type
        )
      }
      return null
    }
  }

  const checkOrganizationExternalDb = async () => {
    try {
      const destination = await api.checkOrganizationExternalDb({
        organizationId,
        token,
      })
      return destination
    } catch (error) {
      if (isHTTPError(error) && error.response.status === 404) {
        setAuthURIError('Invalid organization')
      } else if (isHTTPError(error) && 'detail' in error.response.data) {
        setAuthURIError(
          'Error getting organization destination: ' +
            error.response.data.detail.type
        )
      }
      return null
    }
  }

  const createOrUpdateOrganizationDestination = async (
    destinationId,
    destinationName,
    destinationCreds: BigQueryCredentials | null,
    location
  ) => {
    try {
      const destination = await api.createOrUpdateOrganizationDestination({
        destinationId,
        destinationName,
        destinationCreds,
        location,
        organizationId,
        token,
      })
      return destination
    } catch (error) {
      if (isHTTPError(error) && error.response.status === 404) {
        setAuthURIError('Invalid organization')
      } else if (isHTTPError(error) && 'detail' in error.response.data) {
        setAuthURIError(
          'Error getting organization destination: ' +
            error.response.data.detail.type
        )
      }
      return null
    }
  }

  const removeOrganizationDestination = async () => {
    try {
      const destination = await api.removeOrganizationDestination({
        organizationId,
        token,
      })
      return destination
    } catch (error) {
      if (isHTTPError(error) && error.response.status === 404) {
        setAuthURIError('Invalid organization')
      } else if (isHTTPError(error) && 'detail' in error.response.data) {
        setAuthURIError(
          'Error removing organization destination: ' +
            error.response.data.detail.type
        )
      }
      return null
    }
  }

  const checkOrganizationDestinationStatus = async () => {
    try {
      const destinationStatus = await api.checkOrganizationDestinationStatus({
        organizationId,
        token,
      })
      setCanConfigureDestination(destinationStatus.can_configure_destination)
      return destinationStatus
    } catch (error) {
      if (isHTTPError(error) && error.response.status === 404) {
        setAuthURIError('Invalid organization')
      } else if (isHTTPError(error) && 'detail' in error.response.data) {
        setAuthURIError(
          'Error getting organization destination: ' +
            error.response.data.detail.type
        )
      }
      return null
    }
  }

  const getDestination = async (destinationId) => {
    try {
      const destination = await api.getDestination({
        destinationId,
        organizationId,
        token,
      })
      return destination
    } catch (error) {
      if (isHTTPError(error) && error.response.status === 404) {
        setAuthURIError('Invalid organization')
      } else if (isHTTPError(error) && 'detail' in error.response.data) {
        setAuthURIError(
          'Error getting destination: ' +
            error.response.data.detail.type
        )
      }
      return null
    }
  }

  return {
    authURIError,
    destinations,
    organizationDestination,
    hasConfiguredDestination,
    canConfigureDestination,
    getAllDestinations,
    getDestination,
    getOrganizationDestination,
    checkOrganizationDestinationStatus,
    checkOrganizationExternalDb,
    createOrUpdateOrganizationDestination,
    removeOrganizationDestination,
  }
}

export default useDestinations
